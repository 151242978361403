import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { EncryptDecryptService } from './encrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})
export class TokeninterceptorService implements HttpInterceptor {
  public loginDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

loginDatass: any;
  constructor(
    private _encryptDecrypt: EncryptDecryptService,
    private _Auth: AuthService,
  ) {
    // console.log(res?.result?.token)
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    
    let token = localStorage.getItem('val')
    if (token) {
      
    let res = this._encryptDecrypt.decrypt(token);
    // console.log(res);
    this.loginDatass = res?.result?.token;
    }

    let tokenHeadFer = req.clone({
      setHeaders: {
      Authorization:'bearer '+ this.loginDatass
      }
      });

    return next.handle(tokenHeadFer)
  }
}
