<div class="section">
  <div class="row">
    <div class="wrap col-lg-7">
      <div class="col-lg-7 background">
        <div class="position-absolute images">
          <img
            src="../../assets/img/frame1.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images1">
          <img
            src="../../assets/img/frame2.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images2">
          <img
            src="../../assets/img/frame3.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images3">
          <img
            src="../../assets/img/frame5.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images4">
          <img
            src="../../assets/img/frame6.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images5">
          <img
            src="../../assets/img/frame4.svg"
            class="frames img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="">
          <h5 class="track">
            Track and Manage Your <br />
            Income & Expenses
          </h5>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 contain">
      <a *ngIf="backBtn" (click)="onBack()"
        ><img src="../../../assets/img/back icon.svg" alt="left-icon"
      /></a>
      <div class="security_question">
        <div class="cr8 mt-3">
          <div class="">
            <img src="../../assets/img/atlas-img.svg" routerLink="/" />
            <div class="text"><h6>Atlas</h6></div>
          </div>
          <div class="numba">
            <h6>9/9</h6>
          </div>
        </div>
        <div class="acct">
          <h5 class="hero">Setup Security Questions</h5>
          <p class="details">Kindly provide details for security questions</p>
        </div>
        <form action="" [formGroup]="securityForm">
          <div class="place">
            <div class="col-12 col-md-12">
              <label>Security Question</label>
              <select
                class="form-select"
                formControlName="question"
                id="question"
              >
                <option
                  *ngFor="let question of questions"
                  [value]="question.questionId"
                >
                  {{ question.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="places">
            <div class="col-12 col-md-12">
              <div class="position-relative">
                <label>Security Answer</label>
                <input
                  [type]="eyes ? 'text' : 'password'"
                  id="enterPassword"
                  class="form-control"
                  formControlName="securityAnswer"
                  (keyup)="enterQuestion($event)"
                />
                <div class="eyes" (click)="onOpenPassword()">
                  <img src="../../../assets/img/{{ eyesDetails }}" alt="eyes" />
                </div>
              </div>
            </div>
          </div>
          <div class="places">
            <div class="col-12 col-md-12">
              <div class="position-relative">
                <label>Confirm Security Answer</label>
                <input
                  [type]="eyes ? 'text' : 'password'"
                  id="password1"
                  class="form-control"
                  formControlName="confirmSecurityAnswer"
                  (keyup)="onConfirm($event)"
                />
                <div class="eyes" (click)="onOpenPassword()">
                  <img src="../../../assets/img/{{ eyesDetails }}" alt="eyes" />
                </div>

                <div
                  *ngIf="confirm?.length > 0"
                  [class]="checkPassword ? 'active' : 'error'"
                >
                  {{
                    checkPassword ? "Password match." : "Passwords do not match"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="proceed">
            <button
              class="btn btn-outline crt"
              style="background: #1cbbce"
              type="submit"
              (click)="onSubmit()"
              [disabled]="setQuestion()"
            >
              Proceed
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
