import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'atlasCustomerWeb';

  loader: boolean = false;
  constructor(
    private _loading: LoadingService,
    private platformLocation: PlatformLocation,
    private cdr: ChangeDetectorRef
  ) {
    _loading.loadingSubject.asObservable().subscribe({
      next: (res) => {
        // console.log(res);
        if (res !== null) {
          this.loader = res;
          // this.cdr.detectChanges(); // Manually trigger change detection
        }
  
      },
      error: (err) => {
        // console.log(err?.error);
      },
    });
  }

  ngOnInit(): void {
    // console.log(this.isMobileDevice)
  }

  // isMobileDevice(): boolean {
  //   // Check if the user agent contains any of the common mobile keywords
  //   const userAgent = this.platformLocation.getBaseHrefFromDOM();
  //   return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  // }
  
}
