<div class="section">
  <div class="row">
    <div class="col-lg-7 wrap">
      <div class="col-lg-7 background">
        <div class="position-absolute images">
          <img
            src="../../assets/img/frame1.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images1">
          <img
            src="../../assets/img/frame2.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images2">
          <img
            src="../../assets/img/frame3.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images3">
          <img
            src="../../assets/img/frame5.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images4">
          <img
            src="../../assets/img/frame6.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images5">
          <img
            src="../../assets/img/frame4.svg"
            class="frames img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="">
          <h5 class="track">
            Track and Manage Your <br />
            Income & Expenses
          </h5>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 contain">
      <a *ngIf="backBtn" (click)="onBack()"
        ><img src="../../../assets/img/back icon.svg" alt="left-icon"
      /></a>
      <div [ngSwitch]="routeName">
        <div *ngSwitchCase="'create-password'">
          <div class="create_password">
            <div class="cr8 mt-3">
              <div class="">
                <img src="../../assets/img/atlas-img.svg" />
                <div class="text"><h6>Atlas</h6></div>
              </div>
              <div class="numba">
                <h6>4/6</h6>
              </div>
            </div>
            <div class="acct">
              <h5 class="hero">Create Your Login Password</h5>
              <p class="details">Input Yout Login Password</p>
            </div>
            <form [formGroup]="passwordForm">
              <div class="place">
                <div class="col-12">
                  <div class="position-relative">
                    <label>Create Password</label>
                    <input
                      [type]="eyes?'text':'password'"
                      id="password"
                      placeholder="xxxx xxxx xxxx xxxx"
                      class="form-control"
                      formControlName="password"
                      (change)="enterPassword($event)"
                    />
                    <div class="eyes"
                    (click)="onOpenPassword()"
                    >
                      <img
                        src="../../../assets/img/{{eyesDetails}}"
                        alt="eyes"
                      />
                    </div>
                  </div>

                  <div
                    [class]="
                      passwordForm.get('password')?.hasError('pattern')
                        ? 'error'
                        : 'active'
                    "
                  >
                    Password must be at least 8 characters long.
                  </div>
                  <div
                    [class]="
                      passwordForm.get('password')?.hasError('uppercase')
                        ? 'error'
                        : 'active'
                    "
                  >
                    Password must include at least one uppercase letter.
                  </div>
                  <div
                    [class]="
                      passwordForm.get('password')?.hasError('lowercase')
                        ? 'error'
                        : 'active'
                    "
                  >
                    Password must include at least one lowercase letter.
                  </div>
                  <div
                    [class]="
                      passwordForm.get('password')?.hasError('number')
                        ? 'error'
                        : 'active'
                    "
                  >
                    Password must include at least one digit.
                  </div>
                  <div
                    [class]="
                      passwordForm.get('password')?.hasError('specialChar')
                        ? 'error'
                        : 'active'
                    "
                  >
                    Password must include at least one special character.
                  </div>
                  
                </div>
              </div>
              <div class="places">
                <div class="col-12 col-md-12">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    placeholder="xxxx xxxx xxxx xxxx"
                    class="form-control"
                    formControlName="confirmPassword"
                    (keyup)="onConfirm($event)"
                  />

                  <div
                    *ngIf="confirm?.length > 0"
                    [class]="checkPassword ? 'active' : 'error'"
                  >
                    {{
                      checkPassword
                        ? "Password match."
                        : "Passwords do not match"
                    }}
                  </div>
                </div>
              </div>

              <div class="check">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="myCheckbox"
                    id="flexCheckDefault"
                  />
                </div>
                <div class="">
                  <p class="checkbox">
                    I have read and agreed to the
                    <span
                      ><a routerLink="/termsncondition" class="terms"
                        >terms</a
                      ></span
                    >
                    and
                    <span
                      ><a href="/privacypolicy" class="privacy">
                        privacy policy</a
                      ></span
                    >
                  </p>
                </div>
              </div>

              <div class="proceed">
                <button
                  class="btn btn-outline crt"
                  style="background: #1cbbce"
                  type="submit"
                  [disabled]="onSubmitPassword()"
                  (click)="onProceed()"
                >
                  Proceed
                </button>
              </div>
            </form>
          </div>
        </div>

        <div *ngSwitchCase="'create-pin'">
          <div class="create_pin">
            <div class="cr8 mt-3">
              <div class="">
                <img src="../../assets/img/atlas-img.svg" routerLink="/email" />
                <div class="text"><h6>Atlas</h6></div>
              </div>
              <div class="numba">
                <h6>8/9</h6>
              </div>
            </div>
            <div class="acct">
              <h5 class="hero">Create 4 Digit PIN</h5>
              <p class="details">Create your 4 digits transaction PIN</p>
            </div>
            <form [formGroup]="createPinForm">
              <div class="place">
                <div class="col-12 progress">
                  <div class="verify_input">
                    <div class="d-flex input-details mb-3">
                      <app-otp-input
                        #input="ngModel"
                        [(ngModel)]="userPin"
                        [ngModelOptions]="{ standalone: true }"
                        (keyup)="verifyPin(userPin)"
                        
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="proceed">
                <button
                  class="btn btn-outline crt"
                  style="background: #1cbbce"
                  type="submit"
                  (click)="onSubmitPin()"
                  [disabled]="!verifyPin(userPin)"
                >
                  Proceed
                </button>
              </div>
            </form>
          </div>
        </div>

        <div *ngSwitchDefault>
          <div class="input_details">
            <div class="cr8 mt-3">
              <div class="">
                <img src="../../assets/img/atlas-img.svg" routerLink="/" />
                <div class="text"><h6>Atlas</h6></div>
              </div>
              <div class="numba">
                <h6>2/6</h6>
              </div>
            </div>
            <div class="acct">
              <h5 class="hero">Input Details</h5>
              <p class="details">
                Ensure that Information provided is linked to your BVN
              </p>
            </div>
            <form [formGroup]="feedbackForm">
              <div class="place">
                <div class="col-12 col-md-12">
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="Michael@"
                    class="form-control"
                    formControlName="firstName"
                  />
                  <small
                    *ngIf="
                      feedbackForm.get('firstName')?.invalid &&
                      feedbackForm.get('firstName')?.touched
                    "
                    >*First Name is required</small
                  >
                </div>
              </div>
              <div class="places">
                <div class="col-12 col-md-12">
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="E.g Dozie"
                    class="form-control phold"
                    formControlName="lastName"
                  />
                  <small
                    *ngIf="
                      feedbackForm.get('lastName')?.invalid &&
                      feedbackForm.get('lastName')?.touched
                    "
                    >*Last Name is required</small
                  >
                </div>
              </div>
              <div class="places">
                <div class="col-12 col-md-12">
                  <label>Date of Birth</label>
                  <input
                    type="date"
                    placeholder="E.g dd/mm/yyyy"
                    class="form-control phold"
                    formControlName="dob"
                  />
                  <small
                    *ngIf="
                      feedbackForm.get('dob')?.invalid &&
                      feedbackForm.get('dob')?.touched
                    "
                    >*Date of birth is required</small
                  >
                </div>
              </div>
              <div class="places">
                <div class="col-12 col-md-12">
                  <label>Gender</label>
                  <select
                    class="form-select"
                    id="gender"
                    formControlName="gender"
                  >
                    <option value="" disabled selected>Select</option>

                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>
              <div class="places">
                <div class="col-12 col-md-12">
                  <label>Referral code (optional)</label>
                  <input
                    type="text"
                    id="optionalField"
                    placeholder="3FYY73882H"
                    class="form-control phold"
                    
                  />
                </div>
              </div>
              <div class="places">
                <div class="col-12 col-md-12">
                  <label>Residential Address </label>
                  <input
                    type="text"
                    id="name"
                    placeholder="28, Ribadu Falomo Ikoyi"
                    class="resident form-control"
                    formControlName="rAddress"
                  />
                </div>
              </div>
              <div class="places">
                <div class="col-12 col-md-12">
                  <label>State</label>
                  <select
                    class="form-select"
                    formControlName="states"
                    id="state"
                    placeholder="Select"
                    (change)="Lga($event)"
                  >
                    <option *ngFor="let state of states" [value]="state.id">
                      {{ state.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="places">
                <div class="col-12 col-md-12">
                  <label>LGA</label>
                  <select
                    class="form-select"
                    formControlName="lga"
                    id="lga"
                    placeholder="Select"
                  >
                    <option *ngFor="let lga of lgas" [value]="lga.id">
                      {{ lga.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="places">
                <div class="col-12 col-md-12">
                  <label>Residency type (optional)</label>
                  <select
                    class="form-select"
                    formControlName="residency"
                    id="residency"
                  >
                    <option
                      *ngFor="let residency of residency"
                      [value]="residency.id"
                    >
                      {{ residency.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="proceed">
                <button
                  class="btn btn-outline crt"
                  style="background: #1cbbce"
                  type="submit"
                  (click)="onEnterDetails()"
                  [disabled]="!feedbackForm.valid"
                >
                  Proceed
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
