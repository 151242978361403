import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from '../classes/general';

@Injectable({
  providedIn: 'root'
})
export class ResidencyService {
  public locationSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _http: HttpClient,
    private _encryptDecrypt: EncryptDecryptService

  ) { }

  getResidency() {
    return this._http.get<any>(`${baseUrl}/api/Location/GetResidenceTypes`)
    .subscribe({
      next: (res) => {
        // console.log(res);
        let result = this._encryptDecrypt.decrypt(res.data);
        this.locationSubject.next(result);
      },
      error: (err) => {
        // console.log(err.error);
      },
    });
  }
}
