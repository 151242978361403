<app-toast></app-toast>

<router-outlet></router-outlet>


<ng-container *ngIf="loader">
  <div class="loading">
    <div class="uil-ring-css" style="transform: scale(0.79)">
      <div></div>
        
    </div>
  </div>
</ng-container>
<!-- <section>
  <div class="toaster fade-in-image error" 
  >
      <div class="toast-content">
          <i class="fas fa-soild check fa-xmark"
          ></i>
  
          <div class="message">
              <span class="text text-1">
                  Error
              </span>
              <span class="text text-2">

                  nmndfndnmdfn
              </span>
          </div>
      </div>
      <i class="fa-solid fa-xmark close"></i>
  
      <div class="progress active"></div>
  </div>
</section> -->