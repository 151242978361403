import { Component } from '@angular/core';
import { SecurityQuestionsService } from 'src/app/services/security-questions.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Onboarding } from 'src/app/classes/onboarding';
import { passwordValidator } from 'src/app/classes/password.validator';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { AuthService } from 'src/app/services/auth.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { Observable } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { passportBase64String } from 'src/app/classes/general';

@Component({
  selector: 'app-security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.scss'],
})
export class SecurityQuestionsComponent {
  [x: string]: any;
  securityForm!: FormGroup;
  questions: any[] = [];
  data = '';
  values: any;
  backBtn: boolean = true;
  createSubjectData: any;
  inputSubjectData: any;
  eyes: boolean = false;
  checkPassword: boolean = false;
  pass: any;
  confirm: any;
  eyesDetails: any;

  constructor(
    private _fb: FormBuilder,
    private _securityQuestions: SecurityQuestionsService,
    private _router: Router,
    private _auth: AuthService,
    private _onboarding: OnboardingService,
    private _encryptDecrypt: EncryptDecryptService,
    private _loading: LoadingService
  ) {
    this.initForm();

    this.eyesDetails = 'invisible-eyes.svg';

    this._securityQuestions.securitySubject.pipe().subscribe({
      next: (res) => {
        console.log(res);
        this.questions = res.result;
      },
      error: (err) => {
        console.error('Error fetching questions:', err);
      },
    });

    this._securityQuestions.getQuestions();
    _onboarding.createSubject
      .asObservable()
      .pipe()
      .subscribe((res) => {
        console.log(res);
        this.createSubjectData = res;
      });

    _onboarding.inputSubject
      .asObservable()
      .pipe()
      .subscribe((res) => {
        console.log(res);
        this.inputSubjectData = res;
      });
  }

  initForm() {
    this.securityForm = this._fb.group({
      question: ['', [Validators.required]],
      securityAnswer: ['', [Validators.required]],
      confirmSecurityAnswer: ['', Validators.required],
    });
  }

  onBack() {
    this._router.navigate(['input-details', 'create-pin']);
  }

  enterQuestion(e: any) {
    this.pass = e.target.value;

    if (this.pass == this.confirm) {
      //process the correct data
      this.checkPassword = true;
      console.log('true>>>', 'true');
      return;
    }
    this.checkPassword = false;
  }

  onConfirm(e: any) {
    this.confirm = e.target.value;
    console.log(this.pass, this.confirm);

    if (this.pass == this.confirm) {
      //process the correct data
      this.checkPassword = true;
      console.log('true>>>', 'true');
      return;
    }
    this.checkPassword = false;
    // return
  }

  onOpenPassword() {
    this.eyes = !this.eyes;
    if (this.eyes) {
      this.eyesDetails = 'visible-eyes.svg';
      return;
    }
    this.eyesDetails = 'invisible-eyes.svg';
  }

  setQuestion() {
    if (this.checkPassword && this.securityForm.valid) {
      console.log(this.securityForm.valid, this.checkPassword);
      return false;
    }
    return true;
  }

  onSubmit() {

    // this._loading.onOpenLoader();
    let payload = {
      email: this.createSubjectData.email,
      // mobileNumber: this.createSubjectData.phone,
      // idType: this.createSubjectData.idType,
      // input: this.createSubjectData.input,
      // phoneReferenceCode: this.createSubjectData.phoneReferenceCode,
      emailReferenceCode: this.createSubjectData.emailReferenceCode,
      identityReferenceCode: this.inputSubjectData.verificationCode,

      firstName: this.inputSubjectData.firstName,
      lastName: this.inputSubjectData.lastName,
      dateBirth: this.inputSubjectData.dateBirth,
      gender: this.inputSubjectData.gender,
      residentialAddress: this.inputSubjectData.rAddress,
      state: this.inputSubjectData.states,
      lga: this.inputSubjectData.lga,
      residentialType: this.inputSubjectData.residency,
      password: this.inputSubjectData.password,
      confirmPassword: this.inputSubjectData.confirmPassword,
      transactionPin: this.inputSubjectData.pin,
      securityDetails: {
        questionId: this.securityForm.get('question')?.value,
        answer: this.securityForm.value.securityAnswer,
      },
      roleName: 'user',

      // referralCode: 'SAMPLE123',
      passportType: 'jpg',
      fireBaseToken: '1234567890',
      passportBase64String: passportBase64String,
    };

    // console.log(payload);
    this._auth
      .userCreate(payload)
      .pipe()
      .subscribe({
        next: (result: any) => {
          console.log('Account Created>>>', result);
          console.log(this._encryptDecrypt.decrypt(result.data));
          this.showAccountCreatedAlert();
          this._router.navigate(['login']);
            setTimeout(() => {
              this._loading.onCloseLoader(); // Close the loader when the operation is complete
            }, 1500);
        },
        error: (err) => {
          console.log(this._encryptDecrypt.decrypt(err.error.data));
          console.error('Error creating acct:', err);
        },
      });
  }

  showAccountCreatedAlert() {
      alert('Account Created Successfully. Please Login');
    }
}
