import { Component, Optional } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscribable, finalize } from 'rxjs';
import { Onboarding } from 'src/app/classes/onboarding';
import { AuthService } from 'src/app/services/auth.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { LocateService } from 'src/app/services/locate.service';
import { LocationService } from 'src/app/services/location.service';
import { ResidencyService } from 'src/app/services/residency.service';
import { SecurityQuestionsService } from 'src/app/services/security-questions.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { passwordValidator } from 'src/app/classes/password.validator';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-input-details',
  templateUrl: './input-details.component.html',
  styleUrls: ['./input-details.component.scss'],
})
export class InputDetailsComponent {
  [x: string]: any;
  routeName: any;
  feedbackForm!: FormGroup;
  passwordForm!: FormGroup;
  createPinForm!: FormGroup;
  form!: FormGroup;
  states: any[] = [];
  lgas: any[] = [];
  residency: any[] = [];
  backBtn: boolean = true;
  data = '';
  time: number = 64;
  display: any;
  interval: any;
  resetKey: boolean = false;
  resend: boolean = false;
  values: any;
  questions: any[] = [];
  submitted = false;
  userPin: string = '';
  checkPassword: boolean = false;
  createSubjectData: any;
  confirm: any;
  pass: any;
  eyes: boolean = false;
  eyesDetails: string;
  referenceCode: any;

  info = {
    firstName: '',
    lastName: '',
    dateBirth: '',
    gender: '',
    // referral: '',
    rAddress: '',
    states: '',
    lga: '',
    residency: '',
    password: '',
    confirmPassword: '',
    pin: '',
    verificationCode: '',

    // input: this.reactiveForm.get('idNumber')?.value,
  };

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _router: Router,
    private _encryptDecrypt: EncryptDecryptService,
    private _actRoute: ActivatedRoute,
    private _toaster: ToasterService,
    private _location: LocationService,
    private _locate: LocateService,
    private _residency: ResidencyService,
    private _onboarding: OnboardingService,
    private _loading: LoadingService
  ) {
    console.log(this.eyesDetails);
    this.eyesDetails = 'invisible-eyes.svg';
    this.initForm();

    this._actRoute.paramMap.subscribe((res) => {
      let name = res.get('name');
      this.routeName = name;
    });

    this._location.locationSubject.pipe().subscribe({
      next: (res: any) => {
        console.log(res);
        this.states = res.result;
      },
      error: (err) => {
        console.error('Error fetching states:', err);
      },
    });

    this._locate.locateSubject.pipe().subscribe({
      next: (res: any) => {
        console.log(res);
        this.lgas = res.result;
      },
      error: (err) => {
        console.error('Error fetching local government:', err);
      },
    });

    this._residency.locationSubject.pipe().subscribe({
      next: (res: any) => {
        console.log(res);
        this.residency = res.result;
      },
      error: (err) => {
        console.error('Error fetching residency:', err);
      },
    });

    _onboarding.createSubject
      .asObservable()
      .pipe()
      .subscribe((res) => {
        console.log(res);
        this.createSubjectData = res;
      });
    // this.decryptFile();
  }
  // decryptFile() {
  //   console.log(
  //     this._encryptDecrypt.decrypt('6Nh+9q+kqXSeHgeJCzXYLYxi+BmeUwcR0GFH9kN75EZuoKXOdHeGewaNlkSyfUL2zrppdJUkyqj1LGoRnlPk7hGJXZ9ONBDY78RslCkjwHxuxg71OGCZA8EU8DmplpK/6CDmeLZ0fCvOBdPmWF4HKWIfPmECaD+FUWwqRI3y9VFId40NPXZrIvGColch4f6TS0bYEQ6vYe9iG+Bnnt9tqw==')
  //   );
  // }

  onBack() {
    if ((this.routeName === 'create-password', '')) {
      this.backBtn = true;
      return this._router.navigate(['input-details', 'input-details']);
    }

    // if (this.routeName === 'verify-phone-no') {
    //   this.backBtn = true;
    //   return this._router.navigate(['create-acct', 'create-acct']);
    // }

    // if (this.routeName === 'verify-email') {
    //   this.backBtn = true;
    //   return this._router.navigate(['create-acct', 'email']);
    // }

    return;
  }

  initForm() {
    this.feedbackForm = this._fb.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      // referral: [''],
      rAddress: ['', Validators.required],
      states: ['', Validators.required],
      lga: ['', Validators.required],
      residency: [''],
    });

    this.passwordForm = this._fb.group({
      password: ['', [Validators.required, passwordValidator]],
      confirmPassword: ['', [Validators.required]],
      myCheckbox: [false, Validators.requiredTrue],
    });
  }

  ngOnInit(): void {
    this._location.getStates();
    // console.log(this.states);

    this._residency.getResidency();
  }

  onSubmit() {
    if (this.passwordForm.valid) {
      console.log('Form submitted successfully');
    } else {
      console.log('Form has validation errors');
    }
  }

  verifyPin(event?: any) {
    console.log('userPin>>>', event);
    let userPin = event?.length;
    if (userPin === 4) {
      return true;
    }
    return false;
  }

  Lga(event: any) {
    let data = event.target.value;
    this._locate.getLga(data);
  }

  timer(minute: any) {
    // let minute = 1;
    this.resetKey = true;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds === 0) {
        this.resetKey = false;
        console.log('finished');
        clearInterval(timer);
      }
    }, 1000);
  }

  enterPassword(e: any) {
    this.pass = e.target.value;
  }

  onConfirm(e: any) {
    this.confirm = e.target.value;
    console.log(this.pass, this.confirm);

    if (this.pass == this.confirm) {
      //process the correct data
      this.checkPassword = true;
      console.log('true>>>', 'true');
      return;
    }
    this.checkPassword = false;
    // return
  }

  onSubmitPassword() {
    if (this.checkPassword && this.passwordForm.valid) {
      console.log(this.passwordForm.valid, this.checkPassword);
      return false;
    }
    return true;
  }

  onOpenPassword() {
    this.eyes = !this.eyes;
    if (this.eyes) {
      this.eyesDetails = 'visible-eyes.svg';
      return;
    }
    this.eyesDetails = 'invisible-eyes.svg';
  }

  onEnterDetails() {
    console.log('hello world>>>');
    this._loading.onOpenLoader();
    this.info.firstName = this.feedbackForm.value.firstName;
    this.info.lastName = this.feedbackForm.value.lastName;
    // this.info.referral = this.feedbackForm.value.referral;
    this.info.gender =
      this.feedbackForm.get('gender')?.value === 'female'
        ? 'F'
        : this.feedbackForm.get('gender')?.value === 'male'
        ? 'M'
        : 'select gender';
    this.info.dateBirth = this.feedbackForm.value.dob;
    this.info.rAddress = this.feedbackForm.value.rAddress;
    this.info.states = this.feedbackForm.get('states')?.value;
    this.info.lga = this.feedbackForm.get('lga')?.value;
    this.info.residency = this.feedbackForm.value.residency;
    // this.info.verificationCode = this.referenceCode
    // input: this.reactiveForm.get('idNumber')?.value,

    let payload = {
      firstName: this.info.firstName,
      lastName: this.info.lastName,
      middleName: '',
      dateOfBirth: this.info.dateBirth,
      gender: this.info.gender,
      identificationType: this.createSubjectData.identificationType,
      idNumber: this.createSubjectData.idNumber.toString(),
    };

    console.log('payload>>>', payload);

    this._auth
      .userBiodata(payload)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res: { data: any }) => {
          console.log(this._encryptDecrypt.decrypt(res.data));
          let resultData = this._encryptDecrypt.decrypt(res.data);
          // console.log('User Verified>>>', result);
          if (resultData)  {
            if (resultData.result.verificationCode === '') {
              console.log('User Identification details not verified')
              return 
      
            }
            this.info.verificationCode = resultData.result.verificationCode;
            this._router.navigate(['input-details', 'create-password']);
            setTimeout(() => {
              this._loading.onCloseLoader(); // Close the loader when the operation is complete
            }, 1500);
          }
        },
        error: (err: { error: { data: any } }) => {
          console.error('user not verified', err);
          let result = this._encryptDecrypt.decrypt(err.error.data);
        },
      });
  }

  onProceed() {
    this._loading.onOpenLoader();
    this.info.password = this.passwordForm.value.password;
    this.info.confirmPassword = this.passwordForm.value.confirmPassword;
    this._router.navigate(['input-details', 'create-pin']);
    setTimeout(() => {
      // Your code here

      this._loading.onCloseLoader(); // Close the loader when the operation is complete
    }, 1500); // Simulating a 2-second asynchronous operation
  }

  onSubmitPin() {
    this._loading.onOpenLoader();
    this.info.pin = this.userPin;
    setTimeout(() => {
      // Your code here

      this._loading.onCloseLoader(); // Close the loader when the operation is complete
    }, 1500); // Simulating a 2-second asynchronous operation

    // const onBoarding = new Onboarding();
    this._onboarding.inputFn(this.info);
    this._router.navigate(['security-questions']);
  }
}
