<div class="section">
  <div class="row">
    <div class="col-lg-7 wrap">
      <div class="col-lg-7 background">
        <div class="position-absolute images">
          <img
            src="../../assets/img/frame1.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images1">
          <img
            src="../../assets/img/frame2.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images2">
          <img
            src="../../assets/img/frame3.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images3">
          <img
            src="../../assets/img/frame5.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images4">
          <img
            src="../../assets/img/frame6.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images5">
          <img
            src="../../assets/img/frame4.svg"
            class="frames img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="">
          <h5 class="track">
            Track and Manage Your <br />
            Income & Expenses
          </h5>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 contain">
      <div class="icon">
        <a *ngIf="backBtn" (click)="onBack()"
          ><img src="../../../assets/img/back icon.svg" alt="left-icon"
        /></a>
      </div>
      <div [ngSwitch]="routeName">
        <div *ngSwitchDefault>
          <div class="create_acct">
            <div class="cr8">
              <div class="">
                <img
                  src="../../assets/img/atlas-img.svg"
                  routerLink="/phone-no"
                />
                <div class="text"><h6>Atlas</h6></div>
              </div>
              <div class="numba">
                <h6>1/9</h6>
              </div>
            </div>
            <div class="acct">
              <h5 class="hero">Create Account</h5>
              <p class="details">
                Kindly provide your details to create account
              </p>
            </div>

            <form [formGroup]="reactiveForm">
              <div class="place position-relative">
                <label>Email</label>
                <div class="col-12 col-md-12">
                  <input
                    type="email"
                    id="email"
                    (keyup)="onChange($event)"
                    placeholder="Michael@"
                    class="form-control"
                    formControlName="email"
                    [readonly]="txtEmailVerify"
                    autocomplete="given-name"
                  />

                  <div>
                    <button
                      class="verify position-absolute"
                      *ngIf="isEmailValid"
                      (click)="emailGenerateOtp()"
                    >
                      Verify me
                    </button>
                    <img
                      src="../../../assets/img/tick-circle.svg"
                      alt="tick-circle"
                      class="position-absolute tick"
                      *ngIf="myImage"
                    />
                  </div>
                  <div class="mode">
                    <div
                      class="modal fade"
                      [ngClass]="createMsg ? 'show' : ''"
                      [style]="createMsg ? 'display:block' : 'display:none'"
                      id="staticBackdrop1"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered container">
                        <div class="modal-content">
                          <div>
                            <button
                              type="button"
                              class="btn-close"
                              (click)="onClose()"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <div class="acct">
                              <p class="details1">
                                We’ve sent a verification code to
                                <span class="otp">{{ emailAddress }}</span>
                              </p>
                            </div>

                            <div class="place">
                              <div class="col-12 progress">
                                <form action="" class="verify_input">
                                  <div class="d-flex input-details mb-3">
                                    <app-otp-input
                                      #input="ngModel"
                                      [(ngModel)]="otpValue"
                                      [ngModelOptions]="{ standalone: true }"
                                      (ngModelChange)="onSubmitOtp()"
                                      (keyup)="verifyEmailOtp(otpValue)"
                                    />
                                  </div>
                                  <div class="mb-3 mt-3 text-center timer">
                                    <p>{{ display }}</p>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div class="user">
                              <p class="text-center">
                                Didn’t receive OTP?
                                <span
                                  ><a
                                    (click)="restartCount()"
                                    class="login"
                                    [class.disable]="resetKey"
                                  >
                                    Try again</a
                                  ></span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <small
                    *ngIf="
                      reactiveForm.get('email')?.invalid &&
                      reactiveForm.get('email')?.touched
                    "
                    >*Provide a valid email</small
                  >
                </div>
              </div>

              <!-- <div class="place position-relative">
                <div class="col-12 col-md-12">
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    (keyup)="onChangeNumber($event)"
                    placeholder="E.g 08156784275"
                    class="form-control p-holder"
                    formControlName="phoneNumber"
                    [readonly]="txtNumberVerify"
                  />

                  <div>
                    <button
                      class="verify1 position-absolute"
                      *ngIf="isPhoneNumberValid"
                      (click)="verifyPhoneNo()"
                    >
                      Verify me
                    </button>
                    <img
                      src="../../../assets/img/tick-circle.svg"
                      alt="tick-circle"
                      class="position-absolute tick2"
                      *ngIf="myImageTwo"
                    />
                  </div>
                  <div class="mode">
                    <div
                      class="modal fade"
                      [ngClass]="enterMsg ? 'show' : ''"
                      [style]="enterMsg ? 'display:block' : 'display:none'"
                      id="staticBackdrop"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabindex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered container">
                        <div class="modal-content">
                          <div>
                            <button
                              type="button"
                              class="btn-close"
                              (click)="onClose()"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <div class="acct">
                              <p class="details1">
                                We’ve sent an OTP to
                                <span class="otp"> {{ mobileNumber }}</span>
                              </p>
                            </div>

                            <div class="place">
                              <div class="col-12 progress">
                                <form action="" class="verify_input">
                                  <div class="d-flex input-details mb-3">
                                    <app-otp-input
                                      #input="ngModel"
                                      [(ngModel)]="otpNumberValue"
                                      [ngModelOptions]="{ standalone: true }"
                                      (keyup)="verifyNumberOtp(otpNumberValue)"
                                    />
                                  </div>
                                  <div class="mb-3 mt-3 text-center timer">
                                    <p>{{ display }}</p>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div class="user">
                              <p class="text-center">
                                Didn’t receive OTP?
                                <span>
                                  <a
                                    (click)="restartCount()"
                                    class="logins"
                                    [class.disable]="resetKey"
                                    >Click here</a
                                  ></span
                                >
                                <br />
                                <span
                                  class="change"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  (click)="onClose()"
                                  >Change Mobile Number</span
                                >
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <span
                    class="lds-spinner lds-spinner--center pr-2"
                    *ngIf="spinner"
                  ></span>

                  <small
                    *ngIf="
                      reactiveForm.get('phoneNumber')?.invalid &&
                      reactiveForm.get('phoneNumber')?.touched
                    "
                    >*Phone Number is required</small
                  >
                </div>
              </div> -->

              <div class="mt-4 col-12 col-md-12 position-relative">
                <label>Select verification</label>
                <select
                  [disabled]="confirmIdentity"
                  class="form-select form-select-lg"
                  formControlName="option"
                  (change)="onSelectType($event)"
                >
                  <option value="" disabled selected>
                    Choose a verification option
                  </option>

                  <option value="BVN">BVN</option>
                  <option value="NIN">NIN</option>
                </select>
              </div>

              <div class="col-12 col-md-12" *ngIf="isInputValid === true">
                <label>{{ varOption }}</label>
                <input
                  type="number"
                  id="idNumber"
                  placeholder="E.g 08156784275"
                  class="form-control p-holder"
                  formControlName="idNumber"
                  maxlength="11"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  [readonly]="confirmIdentity"
                />
                <small
                  *ngIf="
                    reactiveForm.get('idNumber')?.dirty &&
                    reactiveForm.get('idNumber')?.hasError('minlength') &&
                    reactiveForm.get('idNumber')?.hasError('pattern')
                  "
                >
                  Phone number must be 11 digits.
                </small>
              </div>

              <div>
                <!-- <img
                  src="../../../assets/img/tick-circle.svg"
                  alt="tick-circle"
                  *ngIf="isIdNumberValid"
                  class="position-absolute tic"
                /> -->
              </div>
              <!--(keyup)="onEnterId($event)" -->

              <div class="proceed">
                <button
                  class="btn btn-outline crt"
                  style="background: #1cbbce"
                  [disabled]="verifyMe()"
                  (click)="onSubmitForm()"
                  type="button"
                >
                  Proceed
                </button>
              </div>
            </form>
            <div class="user">
              <p class="text-center">
                Already a user?
                <span><a routerLink="/login" class="login">Login</a></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
