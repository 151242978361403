import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login-forget-password/login/login.component';
import { ForgetPasswordComponent } from './login-forget-password/forget-password/forget-password.component';
import { EmailOtpComponent } from './login-forget-password/email-otp/email-otp.component';
import { ResetPasswordComponent } from './login-forget-password/reset-password/reset-password.component';
import { GetHelpComponent } from './login-forget-password/get-help/get-help.component';
import { CreateAcctComponent } from './auth/create-acct/create-acct.component';
import { InputDetailsComponent } from './auth/input-details/input-details.component';
import { SecurityQuestionsComponent } from './auth/security-questions/security-questions.component';
import { AuthGuard } from './guard/auth.guard';
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login',
  },
  
  {
    path: 'login',
    component: LoginComponent,
  },

  {
    path: 'forget-password/:name',
    component: ForgetPasswordComponent,
  },

  // {
  //   path: 'create-acct/:name',
  //   component: CreateAcctComponent
  // },

  // {
  //   path: 'input-details/:name',
  //   component: InputDetailsComponent
  // }, 

  {
    path: 'security-questions',
    component: SecurityQuestionsComponent
  },
  {
    path: 'base',
    loadChildren: () => import('../app/base/base.module')
      .then(m => m.BaseModule),
    canActivate: [AuthGuard]
  },
  { path: 'transfer', loadChildren: () => import('./base/profile/profile.module').then(m => m.ProfileModule) }

 

  // {
  //   path: 'email-otp',
  //   component: EmailOtpComponent,
  // },

  // {
  //   path: 'reset-password',
  //   component: ResetPasswordComponent,
  // },

  // {
  //   path: 'get-help',
  //   component: GetHelpComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
