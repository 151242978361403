import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  public createSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public inputSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  public computeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );



  computeFn(data: any) {
    this.computeSubject.next(data)
  }

  createFn(data: any) {
    this.createSubject.next(data);

    // this.createSubject.asObservable()
    // .pipe()
    // .subscribe((res)=> {
    //     console.log(res)
    // })
  }
  inputFn(data: any) {
    this.inputSubject.next(data);

    // this.inputSubject.asObservable()
    // .pipe()
    // .subscribe((res)=> {
    //     console.log(res)
    // })
  }
  constructor() { }
}
