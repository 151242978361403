import { Component, ViewEncapsulation } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToastComponent {
  toastAction: boolean = false;
  success: boolean = false;
  error: boolean = false;
  warning: boolean = false;
  resType: string ;
  content: any;
  constructor(private _toaster: ToasterService) {
    // $.getScript('./assets/js/toast.js');
    _toaster.toasterSubject.asObservable().subscribe((res: any) => {
      if (res?.type == 'success') {
        this.success = true;
        this.warning = false;
        this.error = false;
        this.resType = 'Success';
        this.content = res.content;
        this.toastAction = true;
      } else if (res?.type == 'error') {
        this.success = false;
        this.warning = false;
        this.error = true;
        this.resType = 'Error';
        this.content = res.content;
        this.toastAction = true;
      } else if (res?.type == 'warning') {
        this.success = false;
        this.warning = true;
        this.error = false;
        this.resType = 'Warning';
        this.content = res.content;
        this.toastAction = true;
      }
      setTimeout(() => {
        _toaster.toasterSubject.next(null);
        this.toastAction = false;
      }, 10000);
    });
  }

  onCancel() {
    this.toastAction = false;
    this._toaster.toasterSubject.next(null);
  }
}
