<div class="section">
  <div class="row">
    <div class="wrap col-lg-8">
      <div class="col-lg-8 background">
        <div class="position-absolute images">
          <img
            src="../../assets/img/frame1.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images1">
          <img
            src="../../assets/img/frame2.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images2">
          <img
            src="../../assets/img/frame5.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images3">
          <img
            src="../../assets/img/frame4.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images4">
          <img
            src="../../assets/img/frame3.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images5">
          <img
            src="../../assets/img/frame6.svg"
            class="frames img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="">
          <h5 class="track">
            Track and Manage Your <br />
            Income & Expenses
          </h5>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 contain">
      <div class="icon">
        <a href="" routerLink="/forget-password"
          ><img src="../../../assets/img/back icon.svg" alt="left-icon"
        /></a>
      </div>
      <div class="cr8">
        <div class="">
          <img
            src="../../assets/img/atlas-img.svg"
            routerLink="/input-details"
          />
          <div class="text"><h6>Atlas</h6></div>
        </div>
      </div>
      <div class="email_otp">
        <div class="acct">
          <h5 class="hero">Verify Email</h5>
          <p class="details">
            We’ve sent verification code to
            <span class="otp">email@gmail.com</span>
          </p>
        </div>

        <div class="place">
          <div class="col-12 col-md-12">
            <input
              type="tel"
              id="phone-no"
              name="number"
              placeholder="E.g 08156784275"
              class="form-control p-holder"
              formControlName=""
            />
          </div>
        </div>

        <div class="user">
          <p class="text-center">
            Didn’t receive OTP?
            <span
              ><a routerLink="/reset-password" class="login"
                >Click here</a
              ></span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
