import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  feedbackForm!: FormGroup;
  spinner: any;
  deviceInfo: any;
  forgotPassword: boolean = false;
  feedresetForm: any;
  ForgetPassword: any;
  CreateAccount: any;
  checkPassword: boolean = false;
  confirm: any;
  pass: any;
  eyes: boolean = false;
  eyesDetails: any;
  someVariable: string = '';
  emailVal: any

  constructor(
    private _deviceService: DeviceDetectorService,
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _router: Router,
    private _encryptDecrypt: EncryptDecryptService,
    private _toasterService: ToasterService
  ) {
    this.initForm();
    this.eyesDetails = 'invisible-eyes.svg';
    localStorage.setItem('val', '')
    // this.emailVal = localStorage.getItem('token')? localStorage.getItem('token'): '';
    

    // this.decryptFile();
  }

  // decryptFile() {
  //   console.log(
  //     this._encryptDecrypt.decrypt(
  //       'I+7L5ZuvdzHO28i1hM88dledN+gtPWJaKZ8K9SW4IRXs0tdtCUQpBNHWvWLKOW2b'
  //     )
  //   );
  // }

  initForm() {
    this.feedbackForm = this._fb.group({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
    this.feedbackForm.controls["email"].setValue(localStorage.getItem('token')? localStorage.getItem('token'): '');
  }

  onSubmit() {
    this.spinner = true;
    let payload = {
      email: this.feedbackForm.value.email, //'alabideborah973@gmail.com'
      password: this.feedbackForm.value.password, //'Pelumi@2023'
      // ipAddress: this.ipAddress,
    };

    this._auth
      .onLogin(payload)
      .pipe(
        finalize(() => {
          this.spinner = false;
        })
      )
      .subscribe({
        next: (res) => {
          // console.log(this._encryptDecrypt.decrypt(res.data));

          // if (result.hasError === false) {
            localStorage.setItem('token', this.feedbackForm.value.email);

          // console.log(result);
            localStorage.setItem('val', res?.data);
            this._router.navigate(['base', 'app', 'overview']);
          // }
        },
        error: (err) => {
          console.log(err);
          if (err) {
            let result = this._encryptDecrypt.decrypt(err?.error?.data);
          // console.log(result);
          this._toasterService.alert('error', result?.message);
          }
        },
      });
  }

  onOpenPassword() {
    this.eyes = !this.eyes;
    if (this.eyes) {
      this.eyesDetails = 'visible-eyes.svg';
      return;
    }
    this.eyesDetails = 'invisible-eyes.svg';
  }

  onForgetPassword() {
    this._router.navigate(['forget-password', 'enter-email-address']);
  }

  onCreateAccount() {
    this._router.navigate(['create-acct', 'create-acct']);
  }
}
