import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ngn'
})
export class NgnPipe implements PipeTransform {

  transform(value: number | string, ...args: unknown[]): string {
    if (value === null || value === undefined) return '₦0.00';

    // Convert the value to a number
    const numericValue = typeof value === 'string' ? parseFloat(value.replace(/,/g, '').replace(/₦/g, '')) : value;
    
    // Format the number with commas and the Naira symbol
    const formattedValue = `₦${numericValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    
    return formattedValue;
  }

}
