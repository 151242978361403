<div class="section">
  <div class="row">
    <div class="wrap col-lg-7">
      <div class="col-lg-7 background">
        <div class="position-absolute images">
          <img
            src="../../assets/img/frame1.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images1">
          <img
            src="../../assets/img/frame2.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images2">
          <img
            src="../../assets/img/frame3.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images3">
          <img
            src="../../assets/img/frame5.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images4">
          <img
            src="../../assets/img/frame6.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images5">
          <img
            src="../../assets/img/frame4.svg"
            class="frame img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="">
          <h5 class="track">
            Track and Manage Your <br />
            Income & Expenses
          </h5>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 contain">
      <div class="icon">
        <a *ngIf="backBtn" (click)="onBack()"
          ><img src="../../../assets/img/back icon.svg" alt="left-icon"
        /></a>
      </div>
      <div class="cr8">
        <div class="">
          <img src="../../assets/img/atlas-img.svg" />
          <div class="text"><h6>Atlas</h6></div>
        </div>
      </div>
      <div [ngSwitch]="routeName">
        <!-- <div *ngSwitchCase="'email-otp'">
          <div class="email_otp">
            <div class="acct">
              <h5 class="hero">Verify Email</h5>
              <p class="details">
                We’ve sent verification code to
                <span class="otp"> {{ emailAddress }}</span>
              </p>
            </div>

            <div class="place">
              <div class="col-12 progress">
                <form action="" class="verify_input">
                  <div class="d-flex input-details mb-3">
                    <app-otp-input
                      #input="ngModel"
                      [(ngModel)]="data"
                      [ngModelOptions]="{ standalone: true }"
                      (ngModelChange)="onSubmitOtp()"
                      
                    />
                   
                   
                  </div>
                  <div class="mb-3 mt-3 text-center timer">
                    <p>{{ display }}</p>
                  </div>
                </form>
              </div>
            </div>

            <div class="user">
              <p class="text-center">
                Didn’t receive OTP?
                <span
                  ><a
                    (click)="restartCount()"
                    class="login"
                    [class.disable]="resetKey"
                    >Click here</a
                  ></span
                >
              </p>
            </div>
          </div>
        </div> -->

        <div *ngSwitchCase="'reset-password'">
          <div class="reset_password">
            <div class="acct">
              <h5 class="hero">Reset Password</h5>
              <p class="details">Kindly Input your New Password</p>
            </div>
            <form [formGroup]="feedPasswordResetForm">
              <div class="place">
                <div class="col-12 col-md-12">
                  <div class="position-relative">
                  <label>New Password</label>
                  <input
                    [type]="eyes?'text':'password'"
                    id="password"
                    placeholder="xxxx xxxx xxxx xxxx"
                    class="form-control"
                    formControlName="password"
                    (change)="enterPassword($event)"
                  />
                  <div class="eyes"
                    (click)="onOpenPassword()"
                    >
                      <img
                        src="../../../assets/img/{{eyesDetails}}"
                        alt="eyes"
                      />
                    </div>
                </div>

                <div
                    [class]="
                      feedPasswordResetForm.get('password')?.hasError('pattern')
                        ? 'error'
                        : 'active'
                    "
                  >
                    Password must be at least 8 characters long.
                  </div>
                  <div
                    [class]="
                      feedPasswordResetForm.get('password')?.hasError('uppercase')
                        ? 'error'
                        : 'active'
                    "
                  >
                    Password must include at least one uppercase letter.
                  </div>
                  <div
                    [class]="
                      feedPasswordResetForm.get('password')?.hasError('lowercase')
                        ? 'error'
                        : 'active'
                    "
                  >
                    Password must include at least one lowercase letter.
                  </div>
                  <div
                    [class]="
                      feedPasswordResetForm.get('password')?.hasError('number')
                        ? 'error'
                        : 'active'
                    "
                  >
                    Password must include at least one digit.
                  </div>
                  <div
                    [class]="
                      feedPasswordResetForm.get('password')?.hasError('specialChar')
                        ? 'error'
                        : 'active'
                    "
                  >
                    Password must include at least one special character.
                  </div>
                </div>
              </div>
              <div class="places">
                <div class="col-12 col-md-12">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    id="name"
                    placeholder="xxxx xxxx xxxx xxxx"
                    class="form-control phold"
                    formControlName="confirmPassword"
                    (keyup)="onConfirm($event)"
                  />

                  <div
                  *ngIf="confirm?.length > 0"
                  [class]="checkPassword ? 'active' : 'error'"
                >
                  {{
                    checkPassword
                      ? "Password match."
                      : "Passwords do not match"
                  }}
                </div>
                </div>
              </div>

              <div class="check">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="myCheckbox"
                    id="flexCheckDefault"
                  />
                </div>
                <div class="">
                  <p class="checkbox">
                    I have read and agreed to the
                    <span
                      ><a routerLink="/termsncondition" class="terms"
                        >terms</a
                      ></span
                    >
                    and
                    <span
                      ><a href="/privacypolicy" class="privacy">
                        privacy policy</a
                      ></span
                    >
                  </p>
                </div>
              </div>

              <div class="proceed">
                <button
                  routerLink="/"
                  class="btn btn-outline crt"
                  style="background: #1cbbce"
                  type="submit"
                  [disabled]="onSubmitPassword()"
                  (click)="resetPassword()"
                >
                  Proceed
                </button>
              </div>
            </form>
          </div>
        </div>
        <div *ngSwitchDefault>
          <div class="forgot_password">
            <div class="acct">
              <h5 class="hero">Forgot Password</h5>
              <p class="details">
                Kindly enter your email to reset your password
              </p>
            </div>
            <form [formGroup]="feedbackForm">
              <div class="place">
                <div class="col-12 col-md-12">
                  <label>Email</label>
                  <input
                    type="email"
                    id="email"
                    name="text"
                    placeholder="Michael@gmail.com"
                    class="form-control p-holder"
                    formControlName="email"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                  />

                  <small
                    *ngIf="
                      feedbackForm.get('email')?.invalid &&
                      feedbackForm.get('email')?.touched &&
                      feedbackForm.get('email')?.dirty
                    "
                    >Please enter a valid email address.</small
                  >
                </div>
              </div>
              <div class="proceed">
                <button
                  class="btn btn-outline crt"
                  style="background: #1cbbce"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  type="button"
                  [disabled]="!feedbackForm.valid"
                  (click)="sendEmailOtp()"
                >
                  <!-- (click)="sendEmailOtp()" -->
                  Proceed
                </button>
              </div>
              <div class="mode">
                <!-- Modal -->
                <div
                  class="modal fade"
                  [ngClass]="createMsg ? 'show' : ''"
                  [style]="createMsg ? 'display:block' : 'display:none'"
                  id="staticBackdrop"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered container">
                    <div class="modal-content">
                      <button
                        type="button"
                        class="btn-close"
                        (click)="onClose()"
                        aria-label="Close"
                      ></button>

                      <div class="modal-body">
                        <div class="acct">
                          <p class="details">
                            We’ve sent verification code to
                            <span class="otp"> {{ emailAddress }}</span>
                          </p>
                        </div>
                        <div class="place">
                          <div class="col-12 progress">
                            <form action="" class="verify_input">
                              <div class="d-flex input-details mb-3">
                                <app-otp-input
                                  #input="ngModel"
                                  [(ngModel)]="data"
                                  [ngModelOptions]="{ standalone: true }"
                                 
                                />
                                <!-- (ngModelChange)="onSubmitOtp()" -->
                              </div>
                              <div class="mb-3 mt-3 text-center timer">
                                <p>{{ display }}</p>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div class="user">
                          <p class="text-center">
                            Didn’t receive OTP?
                            <span
                              ><a
                                (click)="restartCount()"
                                class="login"
                                [class.disable]="resetKey"
                                >Click here</a
                              ></span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
