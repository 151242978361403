<div class="section">
  <div class="row">
    <div class="col-lg-8 wrap">
      <div class="col-lg-8 background">
        <div class="position-absolute images">
          <img
            src="../../assets/img/frame1.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images1">
          <img
            src="../../assets/img/frame2.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images2">
          <img
            src="../../assets/img/frame5.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images3">
          <img
            src="../../assets/img/frame4.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images4">
          <img
            src="../../assets/img/frame3.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images5">
          <img
            src="../../assets/img/frame6.svg"
            class="frames img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="">
          <h5 class="track">
            Track and Manage Your <br />
            Income & Expenses
          </h5>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 contain">
      <div class="icon">
        <a routerLink="/login"
          ><img src="../../../assets/img/back icon.svg" alt="left-icon"
        /></a>
      </div>
      <div class="cr8">
        <div class="">
          <img src="../../assets/img/atlas-img.svg" routerLink="/" />
          <div class="text"><h6>Atlas</h6></div>
        </div>
      </div>
      <div class="acct">
        <h5 class="hero">Get Help</h5>
        <p class="details">Kindly state your complaint</p>
      </div>
      <div class="place">
        <div class="col-12 col-md-12">
          <label>First Name</label>
          <input
            type="text"
            id="name"
            placeholder="Michael"
            class="name form-control"
            formControlName=""
          />
        </div>
      </div>
      <div class="places">
        <div class="col-12 col-md-12">
          <label>Email</label>
          <input
            type="email"
            id="text"
            placeholder="Your email"
            class="form-control phold"
            formControlName=""
          />
        </div>
      </div>
      <div class="places">
        <div class="col-12 col-md-12">
          <label>Complaint</label>
          <select
            class="form-select"
            formControlName=""
            id="date"
            placeholder="E.g male"
          >
            <option placeholder="select">Select</option>
            <option value="male"></option>
            <option value="female"></option>
          </select>
        </div>
      </div>
      <div class="places">
        <div class="col-12 col-md-12">
          <label>Description </label>
          <input
            type="text"
            id="name"
            placeholder="28, Ribadu Falomo Ikoyi"
            class="resident form-control"
            formControlName=""
          />
        </div>
      </div>
      <div class="proceed">
        <button
          routerLink="/dashboard"
          class="btn btn-outline crt"
          style="background: #1cbbce"
          type="submit"
        >
          Proceed
        </button>
      </div>
    </div>
  </div>
</div>
