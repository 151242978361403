import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { baseUrl } from '../classes/general';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class LocateService {
  public locateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private _http: HttpClient,
    private _encryptDecrypt: EncryptDecryptService
  ) { }

  getLga(id:any) {
    return this._http.get<any>(`${baseUrl}/api/Location/GetLGAs/${id}`)
    .subscribe({
      next: (res) => {
        // console.log(res);
        let result = this._encryptDecrypt.decrypt(res.data);
        this.locateSubject.next(result);
      },
      error: (err) => {
        // console.log(err.error);
      },
    });
  }
}
