import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, map, take } from 'rxjs';
import { timer } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { NgIf } from '@angular/common';
import { passwordValidator } from 'src/app/classes/password.validator';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  authService: any;
  resetSuccessful: any;

  resetPswd() {
    throw new Error('Method not implemented.');
  }
  feedbackForm!: FormGroup;
  feedPasswordResetForm!: FormGroup;
  spinner: any;
  routeName: any;
  data = '';
  secondsLeft: any;
  referenceCode: any;
  sentTo: any;
  values: any;
  resend: boolean = false;
  time: number = 64;
  display: any;
  interval: any;
  resetKey: boolean = false;
  isLoading = false;
  emailAddress: any;
  backBtn: boolean = true;
  decryptedData!: string;
  otp: any;
  referenceNumber: any;
  createMsg: boolean = false;
  checkPassword: boolean = false;
  confirm: any;
  pass: any;
  eyes: boolean = false;
  eyesDetails: any;

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _encryptDecrypt: EncryptDecryptService,
    private _router: Router,
    private _actRoute: ActivatedRoute
  ) {
    this.initForm();
    this._actRoute.paramMap.subscribe((res) => {
      let name = res.get('name');
      this.routeName = name;
      if (this.routeName == 'reset-password') {
        this.backBtn = false;
      }
    });

    

    this._auth.onGenerateOtpSubject
      .pipe(
        finalize(() => {
          // Set loading state to false regardless of success or error
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (res: any) => {
          console.log(res);
          // let result = this._encryptDecrypt.decrypt(res.data);
          // console.log('Email OTP generation successful', res);
        },
        error: (err: any) => {
          let result = this._encryptDecrypt.decrypt(err.error.data);
          console.log(result);
          console.error('Email OTP generation failed', err);
          // Handle error, show messages, etc.
        },
      });
  }

  ngOnInit(): void {
    // if (this.routeName !== 'enter-email-address') {
    //   this._router.navigate(['forget-password', 'enter-email-address']);
    // }
    this.eyesDetails = 'invisible-eyes.svg';
  }

  onBack() {
    if (this.routeName === 'enter-email-address') {
      this.backBtn = true;
      return this._router.navigate(['login']);
    }

    if (this.routeName === 'email-otp') {
      this.backBtn = true;
      return this._router.navigate(['forget-password', 'enter-email-address']);
    }

    return;
  }

  initForm() {
    this.feedbackForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],

    });

    this.feedPasswordResetForm = this._fb.group({
      password: ['', [Validators.required, passwordValidator]],
      confirmPassword: ['', [Validators.required]],
      myCheckbox: [false, Validators.requiredTrue]
    });
  }

  // sendEmailOtp() {
  //   //  this._router.navigate(['forget-password', 'email-otp']);
  // }

  // emailResetPswd() {
  //   // Set loading state to true
  //   this.isLoading = true;
  //   let payload = {
  //     email: this.feedbackForm.value.email,
  //   };
  //   this.emailAddress = this.feedbackForm.value.email;
  //   // this._auth.emailResetPswd(payload);
  //   //
  //   // this._router.navigate(['forget-password', 'email-otp']);
  //   this.timer(1);
  // }

  onClose() {
    this.createMsg = false;
  }

  sendEmailOtp() {
    let payload = {
      userData: this.feedbackForm.value.email,
    };
    console.log(payload);
    this.emailAddress = this.feedbackForm.value.email;
    this.timer(1);
    this._auth
      .sendEmailOtp(payload)
      .pipe(
        finalize(() => {
          this.spinner = false;
        })
      )

      .subscribe({
        next: (res) => {
          console.log(res);
          console.log(this._encryptDecrypt.decrypt(res.data));
          let response = this._encryptDecrypt.decrypt(res.data);

          this.referenceNumber = response.result.referenceCode;
          console.log(this.referenceNumber);
          this.createMsg = true;
        },
        error:(e) => {
          console.log(this._encryptDecrypt.decrypt(e.error.data))
        }
      });
  }

  verifyEmailOtpPswd(event: any) {
    console.log('otp>>>', event);
    let payload = {
      email: this.feedbackForm.value.email,
      otp: event,
      referenceCode: this.referenceNumber,
    };
    console.log('payload>>>', payload);
    let otp = event?.length;
    if (otp === 4) {
      console.log('true>>>', true);
      this._auth
        .verifyEmailOtpPswd(payload)
        .pipe()


        .subscribe({
          next: (res) => {
            this.onClose();
            this.otp = '';
            // console.log('otpVerified', res);
            // this._encryptDecrypt.decrypt(res.data)
          },
          error: (err) => {
            console.error('Error verifying otp:', err);
          },
        });
    }
  }

  onSubmit() {}

  // onSubmitOtp() {
  //   if (this.data.length === 4) {
  //     this._router.navigate(['forget-password', 'reset-password']);
  //   }

    // console.log(
    //   this._encryptDecrypt.decrypt(
    //     'I+7L5ZuvdzHO28i1hM88dledN+gtPWJaKZ8K9SW4IRXs0tdtCUQpBNHWvWLKOW2b'
    //   )
    // );
  // }

  resetPassword() {
    let payload = {
      email: this.feedbackForm.value.email,
      referenceCode: this.referenceNumber,
      password: this.feedbackForm.value.password,
    };
    this._auth.resetPassword(payload);

    this.emailAddress = this.feedbackForm.value.email.subscribe({
      next: (res: { data: any }) => {
        console.log(this._encryptDecrypt.decrypt(res.data));
        let result = this._encryptDecrypt.decrypt(res.data);
      },
      error: (err: { error: { data: any } }) => {
        let result = this._encryptDecrypt.decrypt(err.error.data);
      },
    });
  }

  enterPassword(e: any) {
    this.pass = e.target.value;
  }

  onConfirm(e: any) {
    this.confirm = e.target.value;
    console.log(this.pass, this.confirm);

    if (this.pass == this.confirm) {
      //process the correct data
      this.checkPassword = true;
      console.log('true>>>', 'true');
      return;
    }
    this.checkPassword = false;
    // return
  }

  onSubmitPassword() {
    if (this.checkPassword && this.feedPasswordResetForm.valid) {
      console.log(this.feedPasswordResetForm.valid, this.checkPassword);
      return false;
    }
    return true;
  }

  onOpenPassword() {
    this.eyes = !this.eyes;
    if (this.eyes) {
      this.eyesDetails = 'visible-eyes.svg';
      return;
    }
    this.eyesDetails = 'invisible-eyes.svg';
  }

  timer(minute: any) {
    // let minute = 1;
    this.resetKey = true;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds === 0) {
        this.resetKey = false;
        console.log('finished');
        clearInterval(timer);
      }
    }, 1000);
  }

  restartCount() {
    let payload = {
      email: this.feedbackForm.value.email,
    };

    this._auth.emailGenerateOtp(payload);
    this.timer(1);
  }
}


function subscribe(arg0: {
  next: (res: any) => void;
  error: (err: any) => void;
}) {
  throw new Error('Function not implemented.');
}

 
// let payload = {
//   firstName: this.info.firstName,
//   lastName: this.info.lastName,
//   middleName: '',
//   dateOfBirth: this.info.dob,
//   gender: this.info.gender,
//   identificationType: this.createSubjectData.idType,
//   idNumber: this.createSubjectData.input,
//   // verificationCode: this.referenceCode
// };
// this._auth
//   .userBiodata(payload)
//   .pipe(
//     finalize(() => {
//       this._loading.onCloseLoader();
//     })
//   )
//   .subscribe({
//     next: (res: { data: any }) => {
//       console.log(this._encryptDecrypt.decrypt(res.data));
//       let result = this._encryptDecrypt.decrypt(res.data);
//       // console.log('User Verified>>>', result);
//       if (result) {
//         this.info.verificationCode = result.result.verificationCode;
//         this._router.navigate(['input-details', 'create-password']);
//         setTimeout(() => {
//           this._loading.onCloseLoader(); // Close the loader when the operation is complete
//         }, 1500);
//       }
//     },
//     error: (err: {error:{data:any} }) => {
//       console.error('user not verified', err);
//       let result = this._encryptDecrypt.decrypt(err.error.data)
//     },
//   });
// 


