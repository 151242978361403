import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { Behavior } from 'popper.js';
import { BehaviorSubject, Observable, finalize, throwError } from 'rxjs';
import { baseUrl, httpAuthOptions, httpOptions } from '../classes/general';
import { catchError, map } from 'rxjs/operators';
import { IdentificationType } from '../classes/general';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  verifyEmailOtpSubject: any;
  createAccount(payload: {
    // console.log(err.error);
    // console.log(res);
    // phoneNumber: any;
  }) {
    throw new Error('Method not implemented.');
  }
  public onGenerateOtpSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  createPassword: any;
  subscribe(arg0: (response: any) => void, arg1: (error: any) => void) {
    throw new Error('Method not implemented.');
  }
  loginData: any;
  public loginSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public titleSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public navTypeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  resetPassword: any;

  constructor(
    private _http: HttpClient,
    private _encryptDecrypt: EncryptDecryptService,
    private _loading: LoadingService
  ) {
    this.loginSubject.asObservable().subscribe({
      next: (res) => {
        //console.log(res);
        this.loginData = res?.result?.token;
      },
      error: (err) => {
        // console.log(err.error);
      },
    });
  }

  onLogin(payload: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(payload),
    };
    // console.log(encryptedPayload)
    return this._http
      .post<any>(
        `${baseUrl}/api/account/login`,
        encryptedPayload,
        httpAuthOptions
      )
      .pipe();
  }

  // onForgotPassword(payload: { email: string }) {
  //   return this._http
  //     .post(`${baseUrl}/api/password/ForgetPassword`, payload, httpAuthOptions)
  //     .pipe();
  // }

  sendEmailOtp(payload: any): Observable<any> {
    // console.log(payload);
    let encryptedPayLoad = {
      data: this._encryptDecrypt.encrypt(payload),
      // data: 'I+7L5ZuvdzHO28i1hM88dledN+gtPWJaKZ8K9SW4IRXs0tdtCUQpBNHWvWLKOW2b',
    };

    return this._http
      .post(
        `${baseUrl}/api/password/send-otp`, //api.atlas.ng/api/password/send-otp
        encryptedPayLoad,
        httpAuthOptions
      )
      .pipe();
  }

  verifyEmailOtpPswd(payload: any): Observable<any> {
    this._loading.onOpenLoader();
    // console.log('payload>>>', payload);
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(payload),
    };

    return this._http
      .post<any>(
        `${baseUrl}/api/password/ForgetPassword`,
        encryptedPayload,
        httpAuthOptions
      )
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      );
  }

  emailGenerateOtp(payload: any): Observable<any> {
    // console.log('payload>>>', payload);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(`${baseUrl}/api/Account/SendEmailOtp/${payload.email}`)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      );
  }

  verifyEmailOtp(payload: any): Observable<any> {
    this._loading.onOpenLoader();
    // console.log('payload>>>', payload);
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(payload),
    };

    return this._http
      .post<any>(
        `${baseUrl}/api/Device/verifychangeotp`,
        encryptedPayload,
        httpAuthOptions
      )
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      );
    //   return this._http
    // .get<any>(`${baseUrl}/api/Device/verifychangeotp/${payload.otpValue}`)
    // .pipe(
    //   map((res: any) => {
    //     let result = this._encryptDecrypt.decrypt(res.otpValue);
    //     this.verifyEmailOtpSubject.next(result);
    //   })
    // );
  }

  onResetPassword(payload: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(payload),
    };
    //console.log(encryptedPayLoad)
    return this._http
      .post<any>(
        `${baseUrl}/api/password/ChangePassword`,
        encryptedPayload,
        httpAuthOptions
      )
      .pipe();
  }

  VerifyUserIdentity(identity: IdentificationType) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = this._encryptDecrypt.encrypt(identity);
    // console.log(identity);
    // console.log('body>>>', body);
    let encrypt = { data: body };
    // console.log('payload>>>',atob(body))

    // const data = this._ensscryptDecrypt.encrypt(identity)
    const url = `${baseUrl}/api/account/verifyuseridentity`;
    return this._http.post<any>(url, encrypt).pipe();
  }

  userCheck(payload: any): Observable<any> {
    // console.log(payload);
    this._loading.onOpenLoader();
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(payload),
    };

    return this._http
      .post(
        `${baseUrl}/api/Account/SendPhoneNumberOtp`,
        encryptedPayload,
        httpAuthOptions
      )
      .pipe();
  }

  verifyNumberOtp(payload: any): Observable<any> {
    this._loading.onOpenLoader();
    // console.log('payload>>>', payload);
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(payload),
    };

    return this._http
      .post<any>(
        `${baseUrl}/api/Account/VerifyPhoneNumberOtp`,
        encryptedPayload,
        httpAuthOptions
      )
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      );
  }

  userBiodata(payload: any): Observable<any> {
    let encryptedPayLoad = {
      data: this._encryptDecrypt.encrypt(payload),
    };
    // console.log('encrypted payload>>>', encryptedPayLoad);
    return this._http
      .post<any>(
        `${baseUrl}/api/account/validateuserbiodata`,
        encryptedPayLoad,
        httpAuthOptions
      )
      .pipe();
  }

  userCreate(payload: any): Observable<any> {
    // console.log('payload>>>', payload);
    let encryptedPayLoad = {
      data: this._encryptDecrypt.encrypt(payload),
    };

    return this._http
      .post<any>(
        `${baseUrl}/api/Account/UserCreate`,
        encryptedPayLoad,
        httpAuthOptions
      )
      .pipe();
  }

  

  getTokenExpirationDate(token: string): Date | null {
    const decoded = this.decodeToken(token);
    if (!decoded.hasOwnProperty('exp')) return null;
    
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token: string): boolean {
    const date = this.getTokenExpirationDate(token);
    if (date === null) return false;
    // console.log(date.valueOf(), new Date().valueOf())
    return date ? date.valueOf() <= new Date().valueOf() : true ;
  }

  private decodeToken(token: string): any {
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }
    return JSON.parse(atob(parts[1]));
  }

  isLoggedIn() {
    const token = localStorage.getItem('val');
    let res = this._encryptDecrypt.decrypt(token);
    // Check if token exists and is not expired
    if (this.isTokenExpired(res?.result?.token)) {
      return true;
    } else {
      return true;
    }
  }

  navTitle(data: string) {
    this.titleSubject.next(data);
  }

  navDisplay(data:boolean) {
    this.navTypeSubject.next(data);
  }
}
