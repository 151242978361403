import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class YourInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req);
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy } from '@angular/common';
import { HashLocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login-forget-password/login/login.component';
import { ForgetPasswordComponent } from './login-forget-password/forget-password/forget-password.component';
import { EmailOtpComponent } from './login-forget-password/email-otp/email-otp.component';
import { ResetPasswordComponent } from './login-forget-password/reset-password/reset-password.component';
import { GetHelpComponent } from './login-forget-password/get-help/get-help.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastComponent } from './components/toast/toast.component';
// import { PasswordResetComponent } from './component/password-reset/password-reset.component';
import { OtpInputComponent } from './components/toast/otp-input/otp-input.component';
import { CreateAcctComponent } from './auth/create-acct/create-acct.component';
import { InputDetailsComponent } from './auth/input-details/input-details.component';
import { LocationService } from './services/location.service';
import { SecurityQuestionsComponent } from './auth/security-questions/security-questions.component';
import { AuthGuard } from './guard/auth.guard';
import { NavbarComponent } from './components/navbar/navbar.component';
import { baseUrl } from './classes/general';
import { TokeninterceptorService } from './services/tokeninterceptor.service';
import { NgnPipe } from './ngn.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
// import { CommaSeparatedDirective } from './directives/comma-separated.directive';
// import { AddCommasDirective } from './directives/add-commas.directive';
// import { OnlyNumbersDirective } from './directives/only-numbers.directive';


// import { ProfileComponent } from './profile/profile.component';

@NgModule({
  declarations: [
    AppComponent,

    LoginComponent,
    ForgetPasswordComponent,
    EmailOtpComponent,
    ResetPasswordComponent,
    GetHelpComponent,
    ToastComponent,
    // PasswordResetComponent,
    CreateAcctComponent,
    InputDetailsComponent,
    SecurityQuestionsComponent,
    NavbarComponent,
    NgnPipe,
   
   
    
    // ProfileComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    OtpInputComponent,
    AppRoutingModule,
  ],
  providers: [
    { provide: 'BaseURL', useValue: baseUrl },
    { provide: HTTP_INTERCEPTORS, useClass: TokeninterceptorService, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
