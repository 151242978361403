import { AbstractControl, ValidationErrors, ValidatorFn, FormGroup, Validators } from '@angular/forms';

export const passwordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.value;

  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

  if (!regex.test(password)) {
    return {
      pattern: true,
      lowercase: !/(?=.*[a-z])/.test(password),
      uppercase: !/(?=.*[A-Z])/.test(password),
      number: !/(?=.*\d)/.test(password),
      specialChar: !/(?=.*[!@#$%^&*()_+])/.test(password),
    };
  }

  return null;
};


