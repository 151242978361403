<div class="section">
  <div class="row">
    <div class="wrap col-lg-7">
      <div class="col-lg-7 background">
        <div class="position-absolute images">
          <img
            src="../../assets/img/frame1.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images1">
          <img
            src="../../assets/img/frame2.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images2">
          <img
            src="../../assets/img/frame3.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images3">
          <img
            src="../../assets/img/frame5.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images4">
          <img
            src="../../assets/img/frame6.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images5">
          <img
            src="../../assets/img/frame4.svg"
            class="frames img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="">
          <h5 class="track">
            Track and Manage Your <br />
            Income & Expenses
          </h5>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 contain">
      <div class="cr8">
        <div class="">
          <img src="../../assets/img/atlas-img.svg" routerLink="/email" />
          <div class="text"><h6>Atlas</h6></div>
        </div>
      </div>
      <div class="acct">
        <h5 class="hero">Login</h5>
        <p class="details">Kindly Login Into your account</p>
      </div>
      <form [formGroup]="feedbackForm">
        <div class="place">
          <div class="col-12 col-md-12">
            <label>Email</label>
            <input
              type="email"
              placeholder="Michael@gmail.com"
              class="form-control p-holder"
              formControlName="email"
            />
            <small
              *ngIf="
                feedbackForm.get('email')?.invalid &&
                feedbackForm.get('email')?.touched
              "
              >*Valid email is required</small
            >
          </div>
        </div>
        <div class="places">
          <div class="col-12 col-md-12">
            <div class="position-relative">
              <label>Password</label>
              <input
                [type]="eyes ? 'text' : 'password'"
                id="password"
                autocomplete="new-password"
                placeholder="xxxx xxxx xxxx xxxx"
                class="form-control p-holder"
                formControlName="password"
              />
              <div class="eyes" (click)="onOpenPassword()">
                <img src="../../../assets/img/{{ eyesDetails }}" alt="img" />
              </div>
            </div>
            <small
              *ngIf="
                feedbackForm.get('password')?.invalid &&
                feedbackForm.get('password')?.touched
              "
              >*enter password please</small
            >
          </div>
        </div>
        <div class="proceed">
          <button
            class="btn btn-outline crt"
            style="background: #1cbbce"
            type="submit"
            (click)="onSubmit()"
            [disabled]="!feedbackForm.valid"
          >
            <span
              class="lds-spinner lds-spinner--center pr-2"
              *ngIf="spinner"
            ></span>
            <span *ngIf="!spinner">Proceed</span>
          </button>
        </div>
      </form>

      <div class="user">
        <p class="text-center">
          <a (click)="onForgetPassword()" class="forgot">Forgot Password?</a>
        </p>
        <br />
        <!-- <p class="text-center new">
          New User?
          <a (click)="onCreateAccount()" class="new-acct"> Create Account </a>
        </p> -->
      </div>
    </div>
  </div>
</div>
