import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { IdentificationType } from 'src/app/classes/general';
import { Observable } from 'rxjs';
import { Onboarding } from 'src/app/classes/onboarding';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-create-acct',
  templateUrl: './create-acct.component.html',
  styleUrls: ['./create-acct.component.scss'],
})
export class CreateAcctComponent implements OnInit {
  spinner: boolean = false;
  routeName: any;
  feedbackForm!: FormGroup;
  reactiveForm!: FormGroup;
  data = '';
  values: any;
  decryptedData!: string;
  secondsLeft: any;
  sentTo: any;
  referenceCode: any;
  time: number = 64;
  display: any;
  interval: any;
  resetKey: boolean = false;
  resend: boolean = false;
  mobileNumber: any;
  numberPhone: any;
  isLoading: boolean = false;
  emailAddress: any;
  backBtn: boolean = true;
  _authService: any;
  formData: any;
  email: any;
  isEmailValid: boolean = false;
  isPhoneNumberValid: boolean = false;
  myImage: boolean = false;
  myImageTwo: boolean = false;
  isInputValid: boolean = false;
  isIdNumberValid: boolean = false;
  idNumber: any;
  idNumberType: string = '';
  varOption: any;
  otpValue: string = '';
  otpNumberValue: string = '';
  identity: IdentificationType = {
    identificationType: '', //BankVerficationNumber,NationalIdentityNumber
    idNumber: '',

    // hasError: boolean = true;
  };

  refNumber: any;
  refCode: any;
  idCode: any;
  enterMsg: boolean = false;
  createMsg: boolean = false;
  txtEmailVerify: boolean = false;
  txtNumberVerify: boolean = false;
  confirmIdentity: boolean = false;
  verifyForm: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _router: Router,
    private _encryptDecrypt: EncryptDecryptService,
    private _actRoute: ActivatedRoute,
    private _toaster: ToasterService,
    private _onboarding: OnboardingService,
    private _loading: LoadingService
  ) {
    this._actRoute.paramMap.subscribe((res) => {
      let name = res.get('name');
      this.routeName = name;
    });

    if ((this.routeName == 'email', '')) {
      this.backBtn = false;
    }
  }

  ngOnInit(): void {
    this.initForm();
    console.log(
      'res',
      this._encryptDecrypt.decrypt(
        'KXz5W8Tc4jM8qRw3WSP/khxdKPqD2DiRMcKze1MRcQU='
      )
    );

    if (this.routeName !== 'create-acct') {
      this._router.navigate(['create-acct', 'create-acct']);
    }
  }

  onBack() {
    if ((this.routeName === 'create-acct', '')) {
      this.backBtn = true;
      return this._router.navigate(['login']);
    }

    if (this.routeName === 'verify-phone-no') {
      this.backBtn = true;
      return this._router.navigate(['create-acct', 'create-acct']);
    }

    if (this.routeName === 'verify-email') {
      this.backBtn = true;
      return this._router.navigate(['create-acct', 'email']);
    }

    return;
  }

  initForm() {
    this.feedbackForm = this._fb.group({});

    this.reactiveForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [
        '',
        [Validators.required, Validators.pattern(/^\+?[0-9]\d{0,11}$/)],
      ],
      option: ['', Validators.required],
      idNumber: [
        null,
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(11),
          Validators.maxLength(11),
        ],
      ],
    });

    
    this.reactiveForm.get('idNumber')?.valueChanges.subscribe((value: any) => {
      console.log('heyy>>>');
      this.isInputValid = true;
      const bvnPayload = {
        identificationType:
          this.reactiveForm.get('option')?.value === 'BVN'
            ? 'BankVerficationNumber'
            : this.reactiveForm.get('option')?.value === 'NIN'
            ? 'NationalIdentityNumber'
            :'', //,NationalIdentityNumber
        idNumber: value,
        
      };
      
      console.log('bvnPayload>>>', bvnPayload.identificationType);
      console.log(value.toString().length);
      if (value.toString().length > 10) {
        this.confirmIdentity = false;
        console.log('bvnPayload>>>', bvnPayload);

        this._auth.VerifyUserIdentity(bvnPayload).subscribe({
          next: (response: any) => {
            console.log('response>>>', response);
            let res = this._encryptDecrypt.decrypt(response.data);
            // console.log(this._encryptDecrypt.decrypt(response.data));
            if (res.statusCode === 'OK') {
              // this.isIdNumberValid = true;
              this.confirmIdentity = true;
            } else {
              // this.isIdNumberValid = false;
              this.confirmIdentity = false;
            }
          },
          error: (err: any) => {
            console.error('error>>>', err);
            let res = this._encryptDecrypt.decrypt(err.error.data);
            this._toaster.alert('error', res.message);
            this.isIdNumberValid = false;
          },
        });
      }

      // if ( this.reactiveForm.get('option')?.value === 'BVN') {
      //   this.numberType = 'BVN'
      //   this.isInputValid = true;
      //   const bvnPayload = {
      //     identificationType: 'BankVerficationNumber', //,NationalIdentityNumber
      //     idNumber:value
      //   }
      //   if (value?.length > 10) {
      //     console.log('bvnPayload>>>',bvnPayload)
      //   }
      // } else {
      //   this.numberType = 'NIN';
      //   this.isInputValid = true;
      //   const ninPayload = {
      //     identificationType: 'NationalIdentityNumber', //,NationalIdentityNumber
      //     idNumber:value
      //   }
      //   if (value?.length > 10) {
      //     console.log('bvnPayload>>>',ninPayload)
      //   }
      // }
    });
  }

  onButtonClick(): void {
    // Handle button click logic
  }

  emailText(e: any) {
    this.email = e.target.value;
  }

  emailGenerateOtp() {
    // Set loading state to true
    this.isLoading = true;
    let payload = {
      email: this.reactiveForm.value.email,
    };
    console.log(payload);
    this.emailAddress = this.reactiveForm.value.email;
    // this._auth.emailGenerateOtp(payload);
    this.timer(1);
    this._auth
      .emailGenerateOtp(payload)
      .pipe(
        finalize(() => {
          this.spinner = false;
        })
      )
      .subscribe({
        next: (res) => {
          // console.log(res);
          console.log(this._encryptDecrypt.decrypt(res.data));
          let resp = this._encryptDecrypt.decrypt(res.data);

          this.refNumber = resp.result.referenceCode;
          console.log(this.refNumber);
          this.createMsg = true;
        },
      });
  }

  onClose() {
    this.createMsg = false;
    this.enterMsg = false;
  }

  validateEmail(email: any) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  onChange(emailValue: any): void {
    // Check if the email format is valid
    let res = emailValue.target.value;
    // this.isEmailValid = this.feedbackForm.get('email').valid;

    if (this.validateEmail(res)) {
      console.log('Email is valid.');
      this.isEmailValid = true;
      // this.myImage = true;
    } else {
      console.log('Email is not valid.');
      this.isEmailValid = false;
      // this.myImage = false;
    }
  }

  onSubmitOtp() {
    // if (this.data.length === 4) {
    //   this.myImage = true;
    //   this.isEmailValid = false;
    // } else {
    //   console.log('Not Verified.');
    //   this.isEmailValid = true;
    // }
    // if (this.data.length !== 4) {
    //   this.myImage = false;
    //   this.isEmailValid = true;
    // } else {
    //   console.log('Not Verified.');
    //   this.isEmailValid = false;
    // }
  }

  verifyEmailOtp(event: any) {
    console.log('otpValue>>>', event);
    let payload = {
      email: this.reactiveForm.value.email, //kachiagu99@gmail.com
      otp: event,
      referenceCode: this.refNumber,
    };
    console.log('payload>>>', payload);

    let otpValue = event?.length;
    if (otpValue === 4) {
      console.log('true>>>', true);
      this._auth
        .verifyEmailOtp(payload)
        .pipe(
          finalize(() => {
            this.spinner = false;
          })
        )
        .subscribe({
          next: (res) => {
            this.onClose();
            this.otpValue = '';
            this.txtEmailVerify = true;
            this.myImage = true;
            this.isEmailValid = false;
            // console.log('otpVerified>>>', res);
            // console.log(
            //   this._encryptDecrypt.decrypt(res.data)
            // );
          },
          error: (err) => {
            console.error('Error verifying otp:', err);
            // this.showIncorrectOtpAlert();
          },

        });
    } else {
      otpValue !== 4;
      this.myImage = false;
    }
  }



  numberType: string = '';
  onSelect(event: any): void {
    console.log('event>>>', event?.target?.value);

    // let res = inputValue.target.value;

    // this.varOption = res;

    // if (res.trim() !== '') {
    //   // alert('Input is valid!');
    //   this.isInputValid = true;
    // } else {
    //   // alert('Please enter text!');
    // }
  }

  onSelectType(event: any) {
    if (event?.target?.value === 'BVN') {
      this.isInputValid = true;
    } else {
      this.isInputValid = true;
    }
  }

  onSubmitForm() {
    this._loading.onOpenLoader();
    let payload = {
      email: this.reactiveForm.value.email,
      // phone: this.reactiveForm.value.phoneNumber,
      identificationType: this.reactiveForm.get('option')?.value === 'BVN'
      ? 'BankVerficationNumber'
      : this.reactiveForm.get('option')?.value === 'NIN'
      ? 'NationalIdentityNumber'
      : '',
      idNumber: this.reactiveForm.get('idNumber')?.value,
      emailReferenceCode: this.refNumber,
      // phoneReferenceCode: this.refCode,
      // identityReferenceCode: this.idCode,
    };
    console.log(payload);
    this._onboarding.createFn(payload);

    this._router.navigate(['input-details', 'input-details']);

    setTimeout(() => {
      // Your code here

      this._loading.onCloseLoader(); // Close the loader when the operation is complete
    }, 1500); // Simulating a 2-second asynchronous operation
  }

  verifyMe() {
    if (this.txtEmailVerify  && this.confirmIdentity) {
      console.log('false');
      return false;
    }

    // && this.txtNumberVerify
    // console.log('true')
    return true;
  }

  // onSubmitForm() {
  //   this._router.navigate(['input-details', 'input-details'])
  // }

  
  onSubmitNumber() {
    // if (this.data.length === 4) {
    //   this.myImageTwo = true;
    //   this.isPhoneNumberValid = false;
    // } else {
    //   console.log('Not Verified.');
    //   this.isPhoneNumberValid = true;
    // }
    // if (this.data.length !== 4) {
    //   this.myImageTwo = false;
    //   this.isPhoneNumberValid = true;
    // } else {
    //   console.log('Not Verified.');
    //   this.isPhoneNumberValid = false;
    // }
  }

 

  validateidNumber(idNumber: any) {
    // Allow only digits, spaces, parentheses, plus sign, and hyphen
    const phoneRegex = /^[0-9\s()+-]+$/;

    // Remove non-digit characters for counting digits
    const digitsOnly = idNumber.replace(/\D/g, '');

    // Validate the length of digits
    return phoneRegex.test(idNumber) && digitsOnly.length >= 11;
  }

  onEnterId(idNumberValue: any): void {
    let res = idNumberValue.target.value;

    if (this.validateidNumber(res)) {
      console.log('Identification number is valid.');
      this.isIdNumberValid = true;
    } else {
      console.log('Identification number is not valid.');
      this.isIdNumberValid = false;
    }

    if (this.idNumber.length !== 11) {
      console.log('Identification number is valid.');
      this.isIdNumberValid = false;
    }
  }

  timer(minute: any) {
    // let minute = 1;
    this.resetKey = true;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      // console.log('seconds>>>', seconds);
      if (seconds === 0) {
        this.resetKey = false;
        console.log('finished');
        clearInterval(timer);
      }
    }, 1000);
  }

  restartCount() {
    let payload = {
      email: this.reactiveForm.value.email,
      // mobileNumber: this.reactiveForm.value.phoneNumber, //08082490988
    };
    // console.log('heyy>>>');
    this._auth.emailGenerateOtp(payload).subscribe({
      next: (res: any) => {
        console.log('res>>>', res);
      },
      error: (err: any) => {
        console.error('errorfromemailGenerateOtp>>>', err);
      },
      complete: () => {
        console.info('resetCountImplementation');
      },
    });
    // this._auth.userCheck(payload);
    this.timer(1);
  }
  //  NUMBER OTP
  // verifyPhoneNo() {
  //   this.spinner = true;
  //   let payload = {
  //     mobileNumber: this.reactiveForm.value.phoneNumber, //08082490988
  //   };
  //   console.log(payload);
  //   this.mobileNumber = this.reactiveForm.value.phoneNumber;
  //   // this._router.navigate(['create-acct', 'verify-phone-no']);
  //   this.timer(1);
  //   this._auth
  //     .userCheck(payload)
  //     .pipe(
  //       finalize(() => {
  //         this.spinner = false;
  //       })
  //     )

  //     .subscribe({
  //       next: (res) => {
  //         console.log(res);
  //         console.log(this._encryptDecrypt.decrypt(res.data));
  //         let response = this._encryptDecrypt.decrypt(res.data);

  //         this.refCode = response.result.referenceCode;
  //         console.log(this.refCode);
  //         this.enterMsg = true;
  //       },
  //     });
  // }  

  // verifyNumberOtp(event: any) {
  //   console.log('otpNumberValue>>>', event);
  //   let payload = {
  //     mobileNumber: this.reactiveForm.value.phoneNumber, //08082490988
  //     otp: event,
  //     referenceCode: this.refCode,
  //   };
  //   console.log('payload>>>', payload);

  //   let otpNumberValue = event?.length;
  //   // console.log('otpNumberValue>>>', otpNumberValue)
  //   if (otpNumberValue === 4) {
  //     console.log('true>>>', true);
  //     this._auth
  //       .verifyNumberOtp(payload)
  //       .pipe(
  //         finalize(() => {
  //           this.spinner = false;
  //         })
  //       )
  //       .subscribe({
  //         next: (res) => {
  //           this.onClose();
  //           (this.otpNumberValue = ''), (this.txtNumberVerify = true);
  //           this.myImageTwo = true;
  //           this.isPhoneNumberValid = false;
  //           console.log('otpVerified>>>', res);
  //           console.log(this._encryptDecrypt.decrypt(res.data));
  //         },
  //         error: (err) => {
  //           console.error('Error verifying otp:', err);
  //         },
  //       });
  //   }
  // }

  // validatePhoneNumber(phoneNumber: any) {
  //   // Allow only digits, spaces, parentheses, plus sign, and hyphen
  //   const phoneRegex = /^[0-9\s()+-]+$/;

  //   // Remove non-digit characters for counting digits
  //   const digitsOnly = phoneNumber.replace(/\D/g, '');

  //   // Validate the length of digits
  //   return phoneRegex.test(phoneNumber) && digitsOnly.length >= 11;
  // }

  // onChangeNumber(phoneNumberValue: any): void {
  //   let res = phoneNumberValue.target.value;

  //   if (this.validatePhoneNumber(res)) {
  //     console.log('Phone number is valid.');
  //     this.isPhoneNumberValid = true;
  //     // this.myImageTwo = true;
  //   } else {
  //     console.log('Phone number is not valid.');
  //     this.isPhoneNumberValid = false;
  //     // this.myImageTwo = false;
  //   }
  // }

}
