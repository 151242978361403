<div class="section">
  <div class="row">
    <div class="col-lg-8 wrap">
      <div class="col-lg-8 background">
        <div class="position-absolute images">
          <img
            src="../../assets/img/frame1.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images1">
          <img
            src="../../assets/img/frame2.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images2">
          <img
            src="../../assets/img/frame5.svg"
            class="img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images3">
          <img
            src="../../assets/img/frame4.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images4">
          <img
            src="../../assets/img/frame3.svg"
            class="img-fluid frame"
            alt="atlas-logo"
          />
        </div>
        <div class="position-absolute images5">
          <img
            src="../../assets/img/frame6.svg"
            class="frames img-fluid"
            alt="atlas-logo"
          />
        </div>
        <div class="">
          <h5 class="track">
            Track and Manage Your <br />
            Income & Expenses
          </h5>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 contain">
      <div class="icon">
        <a routerLink="/facial-recognition"
          ><img src="../../../assets/img/back icon.svg" alt="left-icon"
        /></a>
      </div>
      <div class="cr8">
        <div class="">
          <img src="../../assets/img/atlas-img.svg" />
          <div class="text"><h6>Atlas</h6></div>
        </div>
      </div>
      <div class="reset_password">
      <div class="acct">
        <h5 class="hero">Reset Password</h5>
        <p class="details">Kindly Input your New Password</p>
      </div>
      <div class="place">
        <div class="col-12 col-md-12">
          <label>Create Password</label>
          <input
            type="text"
            id="name"
            placeholder="xxxx xxxx xxxx xxxx"
            class="name form-control"
            formControlName=""
          />
        </div>
      </div>
      <div class="places">
        <div class="col-12 col-md-12">
          <label>Confirm Password</label>
          <input
            type="password"
            id="name"
            placeholder="xxxx xxxx xxxx xxxx"
            class="form-control phold"
            formControlName=""
          />
        </div>
      </div>

      <div class="check">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
        </div>
        <div class="">
          <p class="checkbox">
            I have read and agreed to the
            <span
              ><a routerLink="/termsncondition" class="terms">terms</a></span
            >
            and
            <span
              ><a href="/privacypolicy" class="privacy">
                privacy policy</a
              ></span
            >
          </p>
        </div>
      </div>

      <div class="proceed">
        <button
          routerLink="/get-help"
          class="btn btn-outline crt"
          style="background: #1cbbce"
          type="submit"
        >
          Proceed
        </button>
      </div>
    </div>
    </div>
  </div>
</div>
